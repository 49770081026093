var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.writerTableHeaders,"items":_vm.orders,"search":_vm.$store.state.search,"hide-default-footer":true,"disable-pagination":true},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_vm._v(_vm._s(item.id || ""))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})])]}},{key:"item.writer_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.writer_cost ? ("$" + (item.writer_cost)) : "")+" ")]}},{key:"item.writer_deadline",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.writer_deadline.past_due ? 'text-danger' : 'text-success',staticStyle:{"min-width":"50px !important"}},[_vm._v(" "+_vm._s(item.writer_deadline.date || "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',[_c('v-btn',{attrs:{"color":"primary","small":"","to":{ name: 'Order', params: { id: item.id } }}},[_vm._v("View")]),(_vm.is_available)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.$store.state.dialog = true;
            _vm.activeOrder = item;}}},[_vm._v("Bid")]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{staticClass:"text-left",attrs:{"max-width":"290","transition":"dialog-top-transition"},model:{value:(_vm.$store.state.dialog),callback:function ($$v) {_vm.$set(_vm.$store.state, "dialog", $$v)},expression:"$store.state.dialog"}},[_c('v-card',{attrs:{"loading":_vm.bidsForm.busy}},[_c('v-card-title',{staticClass:"text-h5 text-capitalize"},[_vm._v(" Bid order (#"+_vm._s(_vm.activeOrder.id || "")+") ")]),_c('v-card-text',{staticClass:"text-left"},[_vm._v(" Are you sure you want to bid "),_c('span',{staticClass:"text-weight-bold"},[_vm._v(_vm._s(_vm.activeOrder.title || "")+" ")])]),_c('v-card-actions',[_c('button',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){_vm.$store.state.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":_vm.bidsForm.busy},on:{"click":_vm.bid}},[_vm._v(" Yes, Bid ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }