var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.adminTableHeaders,"items":_vm.orders,"search":_vm.$store.state.search,"hide-default-footer":_vm.api_paginated,"disable-pagination":_vm.api_paginated},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_vm._v(_vm._s(item.id || "--"))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Order', params: { id: item.id } }}},[_c('span',{domProps:{"innerHTML":_vm._s(item.title)}})])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [(item.client_id)?_c('router-link',{attrs:{"to":{ name: 'ClientProfile', params: { id: item.client_id } }}},[_vm._v(_vm._s(item.client_name || "--"))]):_vm._e()]}},{key:"item.editor_name",fn:function(ref){
var item = ref.item;
return [(item.writer_id && item.editor_name)?_c('router-link',{attrs:{"to":{ name: 'WriterProfile', params: { id: item.writer_id || '' } }}},[_vm._v(_vm._s(item.editor_name || "--"))]):_vm._e()]}},{key:"item.writer_name",fn:function(ref){
var item = ref.item;
return [(item.writer_id && item.writer_name)?_c('router-link',{attrs:{"to":{ name: 'WriterProfile', params: { id: item.writer_id || '' } }}},[_vm._v(_vm._s(item.writer_name || "--"))]):_vm._e()]}},{key:"item.writer_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.writer_cost ? ("$" + (item.writer_cost)) : "")+" ")]}},{key:"item.urgency_text",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.urgency_text.past_due ? 'text-danger' : 'text-success',staticStyle:{"min-width":"50px !important"}},[_vm._v(" "+_vm._s(item.urgency_text.date || "--")+" ")])]}},{key:"item.client_deadline",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.client_deadline.past_due ? 'text-danger' : 'text-success',staticStyle:{"min-width":"50px !important"}},[_vm._v(" "+_vm._s(item.client_deadline.date || "--")+" ")])]}},{key:"item.editor_deadline",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.editor_deadline.past_due ? 'text-danger' : 'text-success',staticStyle:{"min-width":"50px !important"}},[_vm._v(" "+_vm._s(item.editor_deadline.date || "--")+" ")])]}},{key:"item.writer_deadline",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.writer_deadline.past_due ? 'text-danger' : 'text-success',staticStyle:{"min-width":"50px !important"}},[_vm._v(" "+_vm._s(item.writer_deadline.date || "--")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"elevation":"0","to":{ name: 'Order', params: { id: item.id } },"fab":"","x-small":""}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }