<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <BaseHeader :title="$route.params.status"></BaseHeader>

      <div class="card mt-2 border-0 bg-white shadow">
        <!-- table headers  -->
        <div class="card-body text-right d-flex" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->

          <!-- <router-link :to="{ name: 'PlaceOrder' }" class="my-auto ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">  New Order</router-link> -->
        </div>
        <!-- table headers  -->

        <!-- // admin -->
        <admin-table
          v-if="userType == userTypes.admin || userType == userTypes.client"
          :orders="orders"
          :api_paginated="true"
        ></admin-table>

        <!-- // client -->
        <!-- <client-table
          v-if="userType == userTypes.client"
          :orders="orders"
        ></client-table> -->

        <!-- // writer -->
        <writer-table
          v-if="userType == userTypes.writer"
          :orders="orders"
        ></writer-table>

        <!-- // editor -->
        <editor-table
          v-if="userType == userTypes.editor"
          :orders="orders"
        ></editor-table>

        <!-- pagination -->
        <div class="card-footer" v-if="orders.length > 0">
          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} orders
          </p>
          <ul class="pagination pagination-flat align-self-center px-0">
            <li class="page-item" v-if="filters.prev_page_url">
              <a class="page-link" @click="navigate(filters.prev_page_url)"
                >← &nbsp; Prev</a
              >
            </li>
            <li class="page-item active">
              <a class="page-link">On Page {{ filters.current_page || "" }}</a>
            </li>
            <li class="page-item" v-if="filters.next_page_url">
              <a class="page-link" @click="navigate(filters.next_page_url)"
                >Next &nbsp; →</a
              >
            </li>
          </ul>
        </div>
        <!-- ./pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AdminTable from "../../components/tables/AdminTable.vue";
// import ClientTable from "../../components/tables/ClientTable.vue";
import WriterTable from "../../components/tables/WriterTable.vue";
import EditorTable from "../../components/tables/EditorTable.vue";

export default {
  name: "OrderListing",
  computed: {
    ...mapState("orders", ["orders", "filters"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },

  components: {
    AdminTable,
    // ClientTable,
    WriterTable,
    EditorTable,
  },

  data() {
    return {
      loading: false,
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    let payload = to.params && to.params.status ? to.params.status : "editing";
    this._getOrders(payload)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    next();
  },

  methods: {
    ...mapActions("orders", ["_getOrders", "_navigate"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },

  mounted() {
    this.loading = true;
    // console.log(this.$route.params.status);
    this.getSidebarMenu();
    this._getOrders(this.$route.params.status || "editing")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}
</style>
